import React from 'react';
import './experience.css';
import { BsPatchCheckFill } from 'react-icons/bs';

function Experience() {
  return (
    <section id='experience'>
      <div className="experience-container">
        {/* Upwork Experience */}
        <div className="experience-card">
          <h3>www.upwork.com</h3>
          <h4>Software Developer</h4>
          <p>Dec 2021 - Present</p>
          <ul>
            <li><BsPatchCheckFill /> Collaborated with clients to implement new features, enhancing user experience and functionality.</li>
            <li><BsPatchCheckFill /> Optimized applications for maximum speed and scalability, refactoring legacy code to improve performance and maintainability.</li>
            <li><BsPatchCheckFill /> Architected and developed robust, scalable features using Ruby and Typescript/Node.js, ensuring adherence to security, scalability, and performance requirements.</li>
            <li><BsPatchCheckFill /> Designed and implemented scalable APIs using Go and Python, deployed on Kubernetes clusters.</li>
          </ul>
        </div>

        {/* Awwaltech Experience */}
        <div className="experience-card">
          <h3>awwaltech.com</h3>
          <h4>Frontend Developer</h4>
          <p>Jan 2024 - Apr 2024</p>
          <ul>
            <li><BsPatchCheckFill /> Developed solutions for Awwaltech, a comprehensive solution for mobile and web applications across various trades, utilizing Next.js, React Native, and Flutter.</li>
            <li><BsPatchCheckFill /> Implemented features and optimized rendering performance for enhanced user experience.</li>
            <li><BsPatchCheckFill /> Kept the Project Manager and IT Leads informed of project progress and managed team tasks effectively.</li>
            <li><BsPatchCheckFill /> Mentored junior team members in engineering best practices and contributed to their professional growth.</li>
          </ul>
        </div>

        <div className="experience-card">
            <h3>syntiumalgo.com</h3>
            <h4>UI / UX Designer & Full Stack Engineer</h4>
            <p>Sep 2023 - Dec 2023</p>
            <ul>
              <li><BsPatchCheckFill /> Designed user interfaces and prototypes to enhance user experience.</li>
              <li><BsPatchCheckFill /> Developed responsive designs with JavaScript and ReactJS.</li>
              <li><BsPatchCheckFill /> Conducted usability testing and incorporated user feedback.</li>
              <li><BsPatchCheckFill /> Developed server-side logic and APIs with Node.js.</li>
              <li><BsPatchCheckFill /> Managed databases and ensured data security and integrity.</li>
              <li><BsPatchCheckFill /> Deployed applications on AWS and managed cloud infrastructure.</li>
              <li><BsPatchCheckFill /> Implemented CI/CD pipelines for streamlined deployment processes.</li>
            </ul>
          </div>


        {/* Luna Africa Experience */}
        <div className="experience-card">
          <h3>www.lunafrica.com</h3>
          <h4>Mobile Developer</h4>
          <p>Mar 2023 - Aug 2023</p>
          <ul>
            <li><BsPatchCheckFill /> Developed mobile applications using React Native, ensuring robust functionality and user experience.</li>
            <li><BsPatchCheckFill /> Led requirements analysis, solution design, and end-to-end delivery of key modules, providing effective business solutions.</li>
            <li><BsPatchCheckFill /> Optimized product and platform performance through proactive monitoring and continuous improvements.</li>
            <li><BsPatchCheckFill /> Designed automation for data pipelines and SaaS integration, optimizing business operations and data flow efficiency.</li>
          </ul>
        </div>
        <div className="experience-card">
           <h3>www.upwork.com</h3>
            <h4>UI / UX Designer</h4>
            <p>Dec 2021 - Present</p>
            <ul>
              <li><BsPatchCheckFill /> Led design projects from research to delivery, ensuring high-impact results.</li>
              <li><BsPatchCheckFill /> Created and maintained design systems for consistent user interfaces across platforms.</li>
              <li><BsPatchCheckFill /> Conducted usability testing and iterated on designs based on user feedback.</li>
              <li><BsPatchCheckFill /> Collaborated with the client to define and implement design solutions.</li>
              <li><BsPatchCheckFill /> Verified final designs to meet specifications and uphold high user experience standards.</li>
            </ul>
          </div>
          
          <div class="experience-card">
              <h3>Watten Developers and Consortium</h3>
              <h4> Software Engineer</h4>
              <p>Sep 2022 - Jan 2023</p>
              <ul>
                  <li><BsPatchCheckFill /> Designed and developed a Google profile and website.</li>
                  <li><BsPatchCheckFill /> Created responsive and mobile-optimized website elements like headers, footers, navigation menus, and call-to-action buttons.</li>
                  <li><BsPatchCheckFill /> Designed, created, and managed databases.</li>
                  <li><BsPatchCheckFill /> Created social media posts and designed graphics aligning with brand identity.</li>
                  <li><BsPatchCheckFill /> Communicated design concepts effectively and incorporated stakeholder feedback for revisions.</li>
                  <li><BsPatchCheckFill /> Successfully launched a fully functional Google profile and responsive website.</li>
                  <li><BsPatchCheckFill /> Developed and managed a comprehensive product database.</li>
                  <li><BsPatchCheckFill /> Crafted visually engaging and brand-aligned social media content and marketing materials.</li>
              </ul>
          </div>



      </div>
    </section>
  );
}

export default Experience;
