// src/components/header/Pitch.jsx
import React from 'react';
import pitchVideo from '../../assets/pitch2.mp4';
import './pitch.css';

const Pitch = () => {
  return (
    <div className="pitch__container">
      <div className="pitch__video-wrapper">
        <video className="pitch__video" controls>
          <source src={pitchVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Pitch;
