import React from 'react'
import './about.css'
import jose from '../../assets/link.jpeg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

function About() {
  return (
    <section id='about'>
      
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">

        <div className="about__me">
          <div className="about__me-image">
            <img src={jose} alt="About Image" />

          </div>

        </div>

        <div className="about__content">
          <div className="about__cards">

            <article className='about__card'>
            <FaAward className='about__icon'/> 
              <h5>Experience</h5>
              <small>3+ years working</small>
            </article>

            <article className='about__card'>
            <FiUsers className='about__icon'/> 
              <h5>Clients</h5>
              <small>20+ Worldwide</small>
            </article>

            <article className='about__card'>
            <VscFolderLibrary className='about__icon'/> 
              <h5>Projects</h5>
              <small>20+ Completed </small>
            </article>

          </div>

          <p>
          I am a full-stack software developer experienced in planning, designing, building, and developing, web and mobile applications. My technology stack includes JavaScript, ReactJS, React Native, NodeJS, Golang, Ruby, Ruby on Rails, Java, and DevOps. Additionally, I am a seasoned UI/UX designer with a keen eye for creating appealing, functional, and user-friendly interfaces. I enjoy applying both design and technical skills to every project.
          </p>

          <a href='#contacts' className='btn btn-primary'>lets Talk</a>

        </div>

      </div>
    
    
    </section>
  )
}

export default About
