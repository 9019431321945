import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
import { FaLinkedin } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";


function Footer() {
  return (
    <footer>
      <a href="" className="footer__logo">JOWAMU</a>

      <ul className="permalinks">

       <li><a href="#">Home</a></li> 
       <li><a href="#about">About</a></li>
       <li><a href="#experience">Experience</a></li>
       <li><a href="#services">Services</a></li>
       <li><a href="#portfolio">Portfolio</a></li>
       <li><a href="#testmonials">Testmonials</a></li>
       <li><a href="#contacts">Contacts</a></li>

      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/joseph-mugo-778247177/"><FaLinkedin /></a>
        <a href="https://web.facebook.com/Wamiti23"><FaFacebookF /></a>
        <a href="https://www.instagram.com/_jowamu_/"><FiInstagram /></a>
        <a href="https://twitter.com/Jowamu8711"><IoLogoTwitter /></a>
        <a href="https://twolink.co/me/profile"><CgProfile /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; JOWAMU All rights reserved</small>
      </div>


    </footer>
  )
}

export default Footer
