import React from 'react';
import './portfolio.css';
import villatech from '../../assets/villatech.png';
import banda from '../../assets/portfolio2.jpg';
import optica from '../../assets/optica.jpg';
import todo from '../../assets/todo.png';
import Luna from '../../assets/luna.png';
import delta from '../../assets/delta.png';
import syntium from '../../assets/syntium.png';
import sick from '../../assets/sick.png';
import Ethereum from '../../assets/ethereum.jpg';
import Ebay from '../../assets/ebay.png';
import Mapty from '../../assets/mapty.png'

const data = [
  {
    id: 1,
    image: Ethereum,
    title: 'Ethereum Blockchain Project',
    github: 'https://github.com/JosephMugo8711/go-ethereum',
    status: 'In Progress',
     demo: 'https://github.com/JosephMugo8711/go-ethereum'
  },
  {
    id: 2,
    image: Mapty,
    title: 'Mapty',
    status: 'In Progress',
    demo: 'https://mapty-gold-tau.vercel.app/',
    github: 'https://github.com/JosephMugo8711/mapty'
  },
  {
    id: 3,
    image: syntium,
    title: 'Syntiumalgo',
    status: 'Web Application Completed',
    github: 'https://syntiumalgo.com/',
    demo: 'https://syntiumalgo.com/'
  },
  {
    id: 4,
    image: optica,
    title: 'Optica Web App',
    github: 'https://optica.africa/',
    status: 'Completed',
    demo: 'https://optica.africa/'
  },
  {
    id: 5,
    image: Luna,
    title: 'Luna-Africa',
    status: 'Mobile Application Completed',
    github: 'https://www.lunafrica.com/',
    demo: 'https://www.lunafrica.com/'
  },
  
  {
    id: 6,
    image: villatech,
    title: 'Villatech',
    status: 'Web Application Completed',
    github: 'https://github.com/JosephMugo8711/villatech',
    demo: 'https://task-dashboard-roan.vercel.app/'
  },
  {
    id: 7,
    image: delta,
    title: 'Delta Finance',
    status: 'Web Application Completed',
    github: 'https://github.com/JosephMugo8711/Delta-finance',
    demo: 'https://delta-coral.vercel.app/'
  },
  {
    id: 8,
    image: Ebay,
    title: 'Ebay-challenge',
    status: 'Web Application Completed',
    github: 'https://github.com/JosephMugo8711/ebay-challenge',
    demo: 'https://github.com/JosephMugo8711/ebay-challenge'
  },
  {
    id: 9,
    image: sick,
    title: 'Sick Ecommerce',
    status: 'Web Application Completed',
    demo: 'https://sick-nine.vercel.app/'
  },
  {
    id: 11,
    image: banda,
    title: 'Banda School',
    status: 'Completed',
    github: 'https://github.com/Emmanuel-FSE/banda-school',
    demo: 'https://github.com/Emmanuel-FSE/banda-school'
  }
];

function Portfolio() {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, github, title, demo, status }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <h4 className="status">{status}</h4>

              <div className="portfolio__item-cta">
                <a href={github} className="btn">
                  Github
                </a>
                <a href={demo} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
      <div className="github">
        <a href="https://github.com/JosephWamiti1178" className="btn">
          View over 100 Projects
        </a>
      </div>
    </section>
  );
}

export default Portfolio;

