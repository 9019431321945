import React, { useRef } from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { RiMessengerLine } from 'react-icons/ri';
import { BsWhatsapp } from 'react-icons/bs';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_65ptl1a', 
      'template_ftome8q', 
      form.current,
      'RxKRy3SK1V9Hc6Nka' 
    )
    .then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Message Sent Successfully',
        showConfirmButton: false,
        timer: 1500
      });
      e.target.reset();
    })
    .catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
      console.error('Email send error:', error);
    });
  };

  return (
    <section id='contacts'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      
      <div className="container contact__container">
        <div className="contact__options">

          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>wamiti8711@gmail.com</h5>
            <a href="mailto:wamiti8711@gmail.com" target='_blank' rel='noreferrer'>Send a message</a>
          </article>

          <article className="contact__option">
            <RiMessengerLine className='contact__option-icon' />
            <h4>Telegram</h4>
            <h5>@jowamublue</h5>
            <a href="https://t.me/jowamublue" target='_blank' rel='noreferrer'>Send a message</a>
          </article>

          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon' />
            <h4>WhatsApp</h4>
            <h5>+254 705049364</h5>
            <a href="https://web.whatsapp.com/send?phone=+254705049364" target='_blank' rel='noreferrer'>Send a message</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your full name' required />
          <input type="email" name='email' placeholder='Your email' required />
          <textarea name="message" rows="7" placeholder='Your message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  );
}

export default Contact;

