import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

function Services() {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">

          <article className="service">

            <div className="service__head">
              <h3>Mobile Application Development</h3>
            </div>

            <ul className='service__list'>

              <li>
                <BiCheck className='service__list-icon' />
                <p> Custom Mobile Application Development.</p>
              </li>

              <li>
                <BiCheck className='service__list-icon' />
                <p> Mobile Application Maintenance.</p>
              </li>

              <li>
                <BiCheck className='service__list-icon' />
                <p> E-commerce Mobile App Development.</p>
              </li>

              <li>
                <BiCheck className='service__list-icon' />
                <p> Financial Technology Mobile Application Development.</p>
              </li>

              <li>
                <BiCheck className='service__list-icon' />
                <p> User Interface (UI) and User Experience (UX) Design.</p>
              </li>

              <li>
                <BiCheck className='service__list-icon' />
                <p> Integration with third-party APIs and services.</p>
              </li>

            </ul>

            </article>
        <article className="service">

  <div className="service__head">
    <h3>System Design</h3>
  </div>

  <ul className='service__list'>

    <li>
      <BiCheck className='service__list-icon' />
      <p> Designing scalable and efficient system architectures.</p>
    </li>

    <li>
      <BiCheck className='service__list-icon' />
      <p> Creating detailed design documents and diagrams.</p>
    </li>

    <li>
      <BiCheck className='service__list-icon' />
      <p> Performance optimization and load balancing.</p>
    </li>

    <li>
      <BiCheck className='service__list-icon' />
      <p> Integration of various subsystems and components.</p>
    </li>

    <li>
      <BiCheck className='service__list-icon' />
      <p> Security design and implementation.</p>
    </li>

    <li>
      <BiCheck className='service__list-icon' />
      <p> Database design and management.</p>
    </li>

    <li>
      <BiCheck className='service__list-icon' />
      <p> Ensuring system reliability and availability.</p>
    </li>

    <li>
      <BiCheck className='service__list-icon' />
      <p> Conducting system performance evaluations and improvements.</p>
    </li>

  </ul>

</article>


        <article className="service">

          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className='service__list'>

          <li>
               <BiCheck className='service__list-icon' />
              <p> Information architecture.</p> 
          </li>

          <li>
               <BiCheck className='service__list-icon' />
              <p> User Research - Focuses on understanding user behaviors, needs through observations.</p> 
          </li>

          <li>
               <BiCheck className='service__list-icon' />
              <p> UX Strategy.</p> 
          </li>

          <li>
               <BiCheck className='service__list-icon' />
              <p> Usability Testing - consists of evaluating a product by testing it with your target users .</p> 
          </li>

          

         

          </ul>

        </article>
        <article className="service">

<div className="service__head">
  <h3>Blockchain Development</h3>
</div>

<ul className='service__list'>

  <li>
    <BiCheck className='service__list-icon' />
    <p>Smart Contract Development - Creating self-executing contracts with the terms of the agreement directly written into code.</p> 
  </li>

  <li>
    <BiCheck className='service__list-icon' />
    <p>Decentralized Application (dApp) Development - Building applications that run on a blockchain or P2P network of computers.</p> 
  </li>

  <li>
    <BiCheck className='service__list-icon' />
    <p>Blockchain Integration - Integrating blockchain solutions with existing systems and applications.</p> 
  </li>

  <li>
    <BiCheck className='service__list-icon' />
    <p>Protocol Maintenance - Ensuring the blockchain protocol is updated, secure, and running smoothly.</p> 
  </li>

  <li>
    <BiCheck className='service__list-icon' />
    <p>Data Security - Implementing robust security measures to protect blockchain data and transactions.</p> 
  </li>

</ul>

</article>

         {/* END OF WEB DEVELOPMENT*/}


         <article className="service">

          <div className="service__head">
            <h3>Software Architecture</h3>
          </div>

          <ul className='service__list'>

          <li>
               <BiCheck className='service__list-icon' />
              <p>User Interface (UI) Service</p> 
          </li>

          <li>
               <BiCheck className='service__list-icon' />
              <p>Data Access Service</p> 
          </li>

          <li>
               <BiCheck className='service__list-icon' />
              <p> Business Logic Service</p> 
          </li>

          <li>
               <BiCheck className='service__list-icon' />
              <p>Integration Service</p> 
          </li>

          <li>
               <BiCheck className='service__list-icon' />
              <p>Security Service</p> 
          </li>

          <li>
               <BiCheck className='service__list-icon' />
              <p>Logging and Monitoring Service</p> 
          </li>

          <li>
               <BiCheck className='service__list-icon' />
              <p>Configuration Service</p> 
          </li>

         

          </ul>

        </article> 
      

         {/* END OF CONTENT CREATION*/}

      </div>

      

      </section>
  )
}

export default Services