import React from 'react';
import './pricing.css';

const PricingTable = () => {
  return (
    <div className="pricing-table-container">
      <h5 className="pricing-header">How Much I Charge?</h5>
      <h2 className="pricing-subheader">Website Application and Mobile App Pricing</h2>
      
      <div className="pricing-cards">
        {/* Basic Plan */}
        <div className="pricing-card">
          <div className="pricing-icon">🛵</div>
          <h3 className="plan-title">Basic</h3>
          <ul className="plan-features">
            <li>Responsive Design</li>
            <li>5 Web Pages</li>
            <li>Regular Updates</li>
            <li className="disabled">No Facebook Ads Monitoring</li>
            <li className="disabled">No Database Integration</li>
            <li className="disabled">No Payment Integration</li>
            <li className="disabled">No Content Management System</li>
          </ul>
          <div className="plan-price">KSH 10K</div>
          <button className="pricing-button">Hire Me</button>
        </div>
        
        {/* Standard Plan */}
        <div className="pricing-card">
          <div className="pricing-icon">🚚</div>
          <h3 className="plan-title">Standard</h3>
          <ul className="plan-features">
            <li>Responsive Design</li>
            <li>More than 5 Web Pages</li>
            <li>Regular Updates</li>
            <li>Facebook Ads Monitoring</li>
            <li>Database Integration</li>
            <li className="disabled">No Payment Integration</li>
            <li className="disabled">No Content Management System</li>
          </ul>
          <div className="plan-price">KSH 30K</div>
          <button className="pricing-button">Hire Me</button>
        </div>
        
        {/* Premium Plan */}
        <div className="pricing-card">
          <div className="pricing-icon">🚀</div>
          <h3 className="plan-title">Premium</h3>
          <ul className="plan-features">
            <li>Responsive Design</li>
            <li>More than 5 Web Pages</li>
            <li>Regular Updates</li>
            <li>Facebook Ads Monitoring</li>
            <li>Database Integration</li>
            <li>Payment Integration</li>
            <li>Content Management System</li>
          </ul>
          <div className="plan-price premium-price">KSH 60K</div>
          <button className="pricing-button premium-button">Hire Me</button>
        </div>

        {/* Mobile App Plan */}
        <div className="pricing-card">
          <div className="pricing-icon">📱</div>
          <h3 className="plan-title">Mobile App</h3>
          <ul className="plan-features">
            <li>Cross-Platform Compatibility</li>
            <li>Custom Features</li>
            <li>Push Notifications</li>
            <li>In-App Purchases</li>
            <li>Data Integration</li>
            <li>Analytics and Reporting</li>
            <li>App Store Deployment</li>
          </ul>
          <div className="plan-price mobile-price premium-price">KSH 80K - 150K</div>
          <button className="pricing-button premium-button">Hire Me</button>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
