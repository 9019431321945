import React, { useState } from 'react';
import Experience from './Experience';
import Skills from './Skills';
import './skillexp.css'; // Fixed the path here

function SkillExp() {
  const [activeTab, setActiveTab] = useState('skills');

  return (
    <section className="skill-exp-container">
      <div className="tabs">
        {/* Ensure these h2 elements are displayed horizontally and can toggle content */}
        <h2
          className={`tab ${activeTab === 'skills' ? 'active' : ''}`}
          onClick={() => setActiveTab('skills')}
        >
          Skills
        </h2>
        <h2
          className={`tab ${activeTab === 'experience' ? 'active' : ''}`}
          onClick={() => setActiveTab('experience')}
        >
          Experience
        </h2>
      </div>

      <div className="tab-content">
        {activeTab === 'skills' ? <Skills /> : <Experience />}
      </div>
    </section>
  );
}

export default SkillExp;
